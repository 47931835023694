// @ts-ignore
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../../../components/shared/seo";
import {
  FadeUpBox,
  FadeIn,
  ImageInRight,
  FadeInRight,
} from "../../../components/shared/transitions";
import { OverlayFillGradient } from "../../../components/shared/overlay";
import { useStaticQuery, graphql } from "gatsby";
import { CaseStudyNav } from "../../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import { ParallaxBanner } from "react-scroll-parallax";
import LayoutCaseStudy from "../../../components/layouts/layout-case-study";
import { QuoteBlock } from "../../../components/shared/quote-block";
import { Helmet } from "react-helmet";

function SJVasPage({ data }) {
  const [animate, setAnimate] = useState(false);

  const stJohnImages = useStaticQuery(graphql`
    query {
      stjohnMockup: file(relativePath: { eq: "stjohn-mac-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stjohnHomeBanner: file(
        relativePath: { eq: "page-layout-banner-vas.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stjohnExamples: file(relativePath: { eq: "stjohn-mockup2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      prevWorkImage: file(relativePath: { eq: "wes-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stJohnLogo: file(relativePath: { eq: "st-john-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      vasMockup: file(relativePath: { eq: "vas-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nextWorkImage: file(relativePath: { eq: "energy-action-casestudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      vasDecorative: file(relativePath: { eq: "vas-decorative.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      angularLogo: file(relativePath: { eq: "logo/angular.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "StJohn WA",
              "datePublished": "2020-03-09",
              "reviewBody": "We have been working with Diviv for around 4 months now and have been very pleased with how they operate. Biggest task to date has been a new brand campaign which meant a new look website. The guys delivered on time and on budget which is what you need when the pressure is on. We hope to continue to work with them for many more years.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO title="St John Ambulance" />
      <LayoutCaseStudy>
        <div
          id="VAS-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image
                    src={stJohnImages.stJohnLogo.childImageSharp.fluid.src}
                  />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Web App Development</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>Volunteer Accounting System (VAS)</h2>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <p>
                      St John has a regionally dispersed team of Volunteers.
                      These teams used to manage accounts in Individual systems.
                      Internal processes were such that heavy manual tasks were
                      required which were prone to error and came with a
                      significant time cost to the organisation and a
                      frustration to customers.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover what else St John is up to by visiting their
                      website:
                      <div className="client-meta__link">
                        <a href="https://stjohnwa.com.au//" target="_blank">
                          St John
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="#intro-section01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section className="image-mockup-section" id="intro-section01">
                  <Container>
                    <Row className="intro-section__image-mock-up">
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn duration={200}>
                            <Image
                              src={
                                stJohnImages.vasMockup.childImageSharp.fluid.src
                              }
                            />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#intro-section02"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="intro-section02">
                  <Container>
                    <Row>
                      <Col lg={6} sm={12} className="text-section">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="mb-5">
                              <h4>The Solution</h4>

                              <p>
                                The development team worked closely with
                                stakeholders to design a new Volunteer
                                Accounting System (VAS), which brought all
                                regional accounting systems into a single source
                                with an easy to use and intuitive web interface
                                with integration into Microsoft GP.
                              </p>
                            </div>

                            <h4>The Benefits</h4>

                            <p>
                              In addition to resolving the cumbersome manually
                              intensive problems from there old System the VAS
                              also includes new functionality such as a Patient
                              Care Records billing component for charging
                              ambulance cases; a Debt Collection component to
                              track debt, send reminders, create payment plans &
                              take payment; a Benefit Fund membership function
                              to create & renew memberships as well as a portal
                              into the Debtors, Creditors & Financial modules
                              including Bank Reconciliation.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12}>
                        <div className="tagline">
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              Oh, that feeling you get when you interact with a
                              great digital product!
                            </FadeIn>
                          </FadeUpBox>
                        </div>

                        <div className="img-wrapper">
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <Image
                                src={
                                  stJohnImages.vasDecorative.childImageSharp
                                    .fluid.src
                                }
                              />
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>
        </div>

        <Scene
          triggerElement="#intro-section"
          reverse={false}
          duration={2000}
          offset={200}
        >
          {(progress, event) => (
            <section className="quote-wrapper">
              <Container>
                <QuoteBlock
                  author="StJohn WA"
                  authorPosition="Corporate Affairs"
                  quoteText="We have been working with Diviv for around 4 months
                             now and have been very pleased with how they operate. 
                             Biggest task to date has been a new brand campaign which 
                             meant a new look website. The guys delivered on time and 
                             on budget which is what you need when the pressure is on. 
                             We hope to continue to work with them for many more years."
                  clientLogoUrl={
                    stJohnImages.stJohnLogo.childImageSharp.fluid.src
                  }
                />
              </Container>
            </section>
          )}
        </Scene>

        <CaseStudyNav
          nextUrl="/our-work/energy-action"
          nextImage={stJohnImages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="Energy Action Portal"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default SJVasPage;
